import React, { FC, ReactNode } from 'react'
import Spacing from '../../components/partials/Spacing'

interface IBlueBanner {
    title: ReactNode | string
    subtitle?: string
    text: ReactNode | string
}

const BlueBanner: FC<IBlueBanner> = ({ title, subtitle, text }) => {
    return (
        <>
            <section className="bg-cyan mobile-margin-top">
                <div className="container-1 py-5">
                    <div className="text-center">
                        <h2 className="h1-like pb-1">{title}</h2>{' '}
                        {subtitle && <div className="cera-pro f-24 fw-400 pb-4">{subtitle}</div>}
                        <div className="w-90 mx-auto">{text}</div>
                    </div>
                </div>
            </section>
            <Spacing />
        </>
    )
}

export default BlueBanner
