import React, { FC, ReactNode } from 'react'

interface IBlackBannerSection {
    title: string
    children: ReactNode | ReactNode[]
}

const BlackBannerSection: FC<IBlackBannerSection> = ({ title, children }) => {
    return (
        <section className="mobile-margin-bottom mobile-margin-top my-5">
            <div className="container-1 bg-dark rounded-1 p-5">
                <div className="d-flex flex-wrap">
                    <div className="col-md-12 col-12 text-center roll-button">
                        <p className="mb-5 paragraph-title text-white cera-pro f-28 fw-700">
                            {title}
                        </p>
                        <div className="black-banner-section-content">{children}</div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default BlackBannerSection
