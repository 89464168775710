import React, { FC, ReactNode } from 'react'
import Spacing from '../../components/partials/Spacing'

type TFeature = {
    id: number
    image: ReactNode
    title: string
    text: string
}

interface IFeaturesSection {
    features: TFeature[]
    title: string
    text?: string | undefined
}

const FeaturesSection: FC<IFeaturesSection> = ({ features, title, text }) => {
    return (
        <>
            <section>
                <div className="container-1 mobile-margin-top">
                    <div className="d-flex flex-wrap justify-content-center mb-5">
                        <div className="col-sm-7 col-10 mb-3">
                            <div className="text-center">
                                <h2 className="h1-like">{title}</h2>
                            </div>
                        </div>
                        {text && (
                            <div className="col-sm-9 col-10 mb-3">
                                <div className="text-center">
                                    <h2 className="h1-like">
                                        <p>{text}</p>
                                    </h2>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="d-flex flex-wrap">
                        {features.map((feature) => (
                            <div key={feature.id} className="col-md col-sm-6 col-12 mt-1">
                                <div className="border-top-0 rounded-1 features-section-container">
                                    <div className="w-100 features-section-image-wrap text-center">
                                        {feature.image}
                                    </div>
                                    <div className="pb-4 px-2 mt-3">
                                        <h3 className="text-center fw-700 cera-pro f-16  lh-29 text-uppercase">
                                            {feature.title}
                                        </h3>
                                        <p className="pt-3 text-center">{feature.text}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <Spacing />
        </>
    )
}

export default FeaturesSection
