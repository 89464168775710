import React, { FC, Fragment, ReactNode } from 'react'
import Spacing from '../../components/partials/Spacing'

type TContentRedirect = {
    title: string
    url: string
}

type TColumnContent = {
    title: string
    text: ReactNode | string
    redirect?: TContentRedirect | undefined
    element: ReactNode
}

interface ITwoColumnsSection {
    title: ReactNode
    content: TColumnContent[]
}

const TwoColumnsSection: FC<ITwoColumnsSection> = ({ title, content }) => {
    return (
        <>
            <section>
                <div className='container-1 mobile-margin-top'>
                    <div className='text-center px-2'>{title}</div>

                    {content.map((item, index) => (
                        <Fragment key={item.title}>
                            {index % 2 === 1 ? (
                                <div className='d-flex flex-wrap flex-column-reverse flex-sm-row pt-5 my-5 align-items-center mobile-margin-top'>
                                    <div className='col-md-6 col-12 '>{item.element}</div>
                                    <div className='offset-md-1 offset-0'></div>
                                    <div className='col-md-5 col-12 '>
                                        <h2>{item.title}</h2>
                                        <div>{item.text}</div>
                                        {item.redirect && (
                                            <a
                                                className='primary-text text-nowrap'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                href={item.redirect.url}>
                                                {item.redirect.title}{' '}
                                                <i className='red-pointer-right'></i>
                                            </a>
                                        )}
                                    </div>
                                </div>
                            ) : (
                                <div className='d-flex flex-wrap pt-5 my-5 align-items-center mobile-margin-reset'>
                                    <div className='col-md-5 col-10 '>
                                        <h2>{item.title}</h2>
                                        <div>{item.text}</div>
                                        {item.redirect && (
                                            <a
                                                className='primary-text text-nowrap'
                                                target='_blank'
                                                rel='noopener noreferrer'
                                                href={item.redirect.url}>
                                                {item.redirect.title}{' '}
                                                <i className='red-pointer-right'></i>
                                            </a>
                                        )}
                                    </div>
                                    <div className='offset-md-1'></div>
                                    <div className='col-md-6  col-12 '>{item.element}</div>
                                </div>
                            )}
                        </Fragment>
                    ))}
                </div>
            </section>
            <Spacing />
        </>
    )
}

export default TwoColumnsSection
