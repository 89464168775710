import React, { FC, useEffect, useRef, useState } from 'react'
import Layout from '../../components/Layout'
import HeaderSEO from '../../components/partials/HeaderSEO'
import HeroSection from '../../components/sections/HeroSection'
import BlueBanner from '../../components/sections/BlueBanner'
import FeaturesSection from '../../components/sections/FeaturesSection'
import DiagramSection from '../../components/sections/DiagramSection'
import VideoSection from '../../components/sections/VideoSection'
import TwoColumnsSection from '../../components/sections/TwoColumnsSection'
import BlogSection from '../../components/sections/BlogSection'
import Logos from '../../components/partials/Logos'
import { StaticImage } from 'gatsby-plugin-image'
import { createAndAppendScript, removeScripts } from '../../helpers/utils/scriptHandlers'
import Spacing from '../../components/partials/Spacing'
import BlackBannerSection from '../../components/sections/BlackBannerSection'
import Diagram from '../../resources/images/graphics/S3_Diagram_new.gif'
import get from 'lodash/get'
import { graphql } from 'gatsby'

const KafkaToAwsS3: FC = () => {
    const scriptContainerRef = useRef(null)
    const ref = useRef(null)
    const [video, setVideo] = useState({ isOpen: false, title: '', url: '' })
    const pageSEO = get(this, 'props.data.allContentfulPages.edges[0].node')
    const features = [
        {
            id: 1,
            title: 'Backup/Restore Kafka Topics',
            text: 'Save on disaster recovery infrastructure costs by backing up data in S3.',
            image: (
                <StaticImage
                    className='feature-section-item-image'
                    src='../../resources/images/vectors/backup-restore.svg'
                    placeholder='tracedSVG'
                    alt='Backup/Restore Kafka Topics'
                />
            ),
        },
        {
            id: 2,
            title: 'Hydrate your Data Lake',
            text: 'Sink to S3 whilst optimizing for downstream  analytics.',
            image: (
                <StaticImage
                    className='feature-section-item-image'
                    src='../../resources/images/vectors/data-lake.svg'
                    placeholder='tracedSVG'
                    alt='Hydrate your Data Lake'
                />
            ),
        },
        {
            id: 3,
            title: 'Simplify sourcing from S3',
            text: 'Source new, complex data sources such as XML.',
            image: (
                <StaticImage
                    className='feature-section-item-image'
                    src='../../resources/images/vectors/source.svg'
                    placeholder='tracedSVG'
                    alt='Simplify sourcing from S3'
                />
            ),
        },
        {
            id: 4,
            title: 'Power event-driven apps & GenAI',
            text: 'Feed downstream event-driven apps with data sitting in S3.',
            image: (
                <StaticImage
                    className='feature-section-item-image'
                    src='../../resources/images/vectors/event-driven.svg'
                    placeholder='tracedSVG'
                    alt='Power event-driven apps & GenAI'
                />
            ),
        },
    ]
    const columnsContent = [
        {
            title: 'Single-click backup/restore when managed through Lenses',
            text: 'Use the S3 connector paired with the Lenses Developer Experience for Kafka to backup/restore your Kafka topics with just a single click.',
            element: (
                <div className='cloud-aws-msk-video'>
                    <iframe
                        src='//player.vimeo.com/video/874893270'
                        title='Navigating & Querying Apache Kafka with SQL from Lenses.io on Vimeo'
                        data-src='//player.vimeo.com/video/876384955 '
                        style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '100%',
                        }}
                        frameBorder='0'
                        allow='autoplay; fullscreen'
                        allowFullScreen></iframe>
                </div>
            ),
        },
        {
            title: 'How to source XML files in Amazon S3 into a Kafka topic. Then query it with SQL. ',
            text: (
                <>
                    <span>
                        XML data still exists, and it can be a pain to deal with. Here we
                        demonstrate how to:{' '}
                    </span>
                    <ul style={{ fontSize: '16px' }}>
                        <li>Deploy a Kafka source connector from Lenses</li>
                        <li>Pull in the XML data from Kafka</li>
                        <li>Download it to CSV</li>
                    </ul>
                </>
            ),
            element: (
                <div className='cloud-aws-msk-video'>
                    <iframe
                        src='//player.vimeo.com/video/876384955 '
                        title='Navigating & Querying Apache Kafka with SQL from Lenses.io on Vimeo'
                        data-src='//player.vimeo.com/video/876384955 '
                        style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '100%',
                        }}
                        frameBorder='0'
                        allow='autoplay; fullscreen'
                        allowFullScreen></iframe>
                </div>
            ),
        },
    ]

    const blogs = [
        {
            image: (
                <StaticImage
                    className='w-100 rounded-top-1'
                    src='../../resources/images/screenshots/S3Connector.jpg'
                    placeholder='tracedSVG'
                    alt='S3 connector'
                />
            ),
            title: (
                <div>
                    <p className='d-inline cera-pro'>
                        Mission critical data flows with the open-source{' '}
                    </p>
                    <h3 className='cera-pro f-19 d-inline'> Lenses Kafka </h3>{' '}
                    <p className='d-inline cera-pro'>connector for S3</p>
                </div>
            ),
            type: 'BLOG',
            url: '/blog/2023/09/open-source-lenses-kafka-s3-connector/',
        },
        {
            image: (
                <StaticImage
                    className='w-100 rounded-top-1'
                    src='../../resources/images/screenshots/backup-restore.jpg'
                    placeholder='tracedSVG'
                    alt='Data Capture and Kafka'
                />
            ),
            title: (
                <div>
                    <p className='d-inline cera-pro'> Single click</p>
                    <h3 className='cera-pro f-19 d-inline'> Kafka </h3>{' '}
                    <p className='d-inline cera-pro'>topic backup</p>
                </div>
            ),
            type: 'BLOG',
            url: '/blog/2023/09/backup-restore-kafka-topics-s3/ ',
        },
        {
            image: (
                <StaticImage
                    className='w-100 rounded-top-1'
                    src='../../resources/images/screenshots/Webinar.png'
                    placeholder='tracedSVG'
                    alt='Data Capture and Kafka'
                />
            ),
            title: (
                <div>
                    <p className='d-inline cera-pro'>Lenses 5.3 in</p>
                    <h3 className='cera-pro f-19 d-inline'> 30 minutes </h3>{' '}
                    <p className='d-inline cera-pro'>(new connector experience) </p>
                </div>
            ),
            type: 'WEBINAR',
            callback: () =>
                setVideo({
                    isOpen: true,
                    title: 'Lenses 5.3 in 30 minutes (new connector experience)',
                    url: '//player.vimeo.com/video/874030914',
                }),
            url: 'https://vimeo.com/manage/videos/874030914',
        },
    ]

    useEffect(() => {
        const addToAnyScript = createAndAppendScript(scriptContainerRef, {
            src: 'https://static.addtoany.com/menu/page.js',
            async: true,
        })
        const buttonsScript = createAndAppendScript(scriptContainerRef, {
            src: 'https://buttons.github.io/buttons.js',
            async: true,
        })

        return () => removeScripts(scriptContainerRef, [addToAnyScript, buttonsScript])
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setVideo((current) => ({ ...current, isOpen: false }))
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)

        // Unbind the event listener on cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    return (
        <Layout>
            {pageSEO && <HeaderSEO pageSEO={pageSEO} />}
            <StaticImage
                className='home-fixed-top'
                src='../../resources/images/Homepage/hero_lens.svg'
                alt='Home Lenses'
                placeholder='blurred'
            />
            <HeroSection
                breadcrumbs={
                    <p className='title breadcrumb-text pt-4'>
                        <a href='/architecture-and-integrations/' className='title_text'>
                            PRODUCT
                        </a>{' '}
                        /{' '}
                        <a href='/kafka-connectors/' className='title_text font-weight-bold'>
                            CONNECTORS
                        </a>
                    </p>
                }
                image={
                    <StaticImage
                        className='img-fluid mobile-margin-top-half'
                        src='../../resources/images/screenshots/hero-image-stream-reactor-s3.png'
                        placeholder='tracedSVG'
                        alt='Kafka to AWS S3'
                    />
                }
                title={<>Kafka S3 connector for enterprise-grade data flows</>}
                text={
                    <>
                        <p className='hero-description-dark pr-5 mt-5'>
                            Feature-packed, Apache 2 licensed, fully open-source and available with
                            enterprise support.
                        </p>
                        <div className='mt-5 roll-button buttons-modifier'>
                            <a
                                className='bg-light-blue text-white f-13 footer-cta'
                                href={
                                    'https://github.com/lensesio/stream-reactor/tree/master/kafka-connect-aws-s3'
                                }
                                target={'_blank'}
                                rel='noreferrer'>
                                <span data-hover='Download free open-source '>
                                    Download free open-source{' '}
                                </span>
                            </a>
                            <a
                                className='bg-red text-white ipad-connector-button'
                                href='/enterprise-support/?connector=aws-s3'>
                                <span data-hover='Request enterprise support'>
                                    Request enterprise support
                                </span>
                            </a>
                        </div>
                    </>
                }
            />
            <Logos width={100} />
            <Spacing />
            <BlueBanner
                title={'The most powerful Kafka S3 Connector out there'}
                text={
                    <>
                        <p className='paragraph-textero-description-dark mt-5 '>
                            Amazon S3 is the de facto cloud storage solution, and a strategic
                            component of any Apache Kafka running in AWS.
                        </p>
                        <p className='paragraph-text'>
                            Built by the biggest contributors to open-source Kafka connectors, the
                            Lenses Kafka S3 connector offers more flexibility and power to address
                            S3 data integration challenges, whilst avoiding vendor-lock in.
                        </p>
                    </>
                }
            />
            <FeaturesSection
                features={features}
                title={'What can you do with the Kafka to S3 connector?'}
            />
            <DiagramSection
                title={
                    <h2 className='h1-like' style={{ paddingBottom: 0 }}>
                        Kafka S3 connector architecture
                    </h2>
                }
                image={
                    <img
                        className='p-2 w-100'
                        src={Diagram}
                        alt='Kafka S3 connector architecture'
                    />
                }
            />
            <VideoSection
                title={'Kafka with Amazon S3: Architecture & top use cases'}
                text={
                    'An architecture pattern for sending data back and forth between Apache Kafka and Amazon S3, to address a range of use cases:'
                }
                video={{
                    url: '//player.vimeo.com/video/874891392',
                    title: 'Kafka with Amazon S3: Architecture & top use cases',
                }}
            />
            <TwoColumnsSection
                title={
                    <h2
                        className='h1-like w-50 mx-auto mobile-w-100'
                        style={{ paddingBottom: 0, marginBottom: '-20px' }}>
                        See the connector in action
                    </h2>
                }
                content={columnsContent}
            />
            <BlogSection title={'Kafka to S3 resources'} blogs={blogs} />
            <BlackBannerSection title={'Try the Kafka S3 connector'}>
                <div className='black-banner-section-item'>
                    <a
                        className='bg-light-blue text-white f-13 footer-cta'
                        href={
                            'https://github.com/lensesio/stream-reactor/tree/master/kafka-connect-aws-s3'
                        }
                        target={'_blank'}
                        rel='noreferrer'>
                        <span data-hover='Download free open-source '>
                            Download free open-source{' '}
                        </span>
                    </a>
                    <a
                        className='bg-red text-white ipad-connector-button'
                        href='/enterprise-support/?connector=aws-s3'>
                        <span data-hover='Request enterprise support'>
                            Request enterprise support
                        </span>
                    </a>
                </div>
            </BlackBannerSection>
            {video.isOpen && (
                <div
                    className='videoContainer'
                    ref={ref}
                    style={{
                        position: 'fixed',
                        boxShadow: '0 4px 25px 22px rgba(0, 0, 0, 0.3)',
                        zIndex: 9999,
                        width: '100%',
                        height: '100%',
                        maxWidth: '768px',
                        maxHeight: '460px',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}>
                    {/*<StaticImage*/}
                    {/*    src={'../../resources/images/icons/close-icon.svg'}*/}
                    {/*    alt={'close icon'}*/}
                    {/*    className={'webinar-close-btn'}*/}
                    {/*    onClick={() => setVideo((current) => ({...current, isOpen: false}))}*/}
                    {/*/>*/}
                    <iframe
                        src={video.url}
                        title={video.title}
                        style={{
                            // position: 'absolute',
                            background: 'black',
                            width: '100%',
                            height: '100%',
                        }}
                        frameBorder='0'
                        allow='autoplay; fullscreen; picture-in-picture'
                        allowFullScreen></iframe>
                </div>
            )}
        </Layout>
    )
}

export default KafkaToAwsS3

export const pageQuery = graphql`
    query kafkaToAWSS3 {
        allContentfulPages(
            filter: { pageReference: { pageIdentifier: { eq: "Connector_Kafka_To_AWSS3" } } }
        ) {
            edges {
                node {
                    title {
                        title
                    }
                    keywords {
                        keywords
                    }
                    description {
                        description
                    }
                    oGimage {
                        fluid(quality: 100) {
                            src
                        }
                    }
                }
            }
        }
    }
`
