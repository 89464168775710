import {MutableRefObject} from "react"

export const createAndAppendScript = (scriptRef: MutableRefObject<any>, options: { src: string, async: boolean }) => {
    const script = document.createElement('script')
    script.src = options.src
    script.async = options.async

    if (scriptRef.current) {
        scriptRef.current.appendChild(script)
    }

    return script
}

export const removeScripts = (scriptRef: MutableRefObject<any>, scripts: HTMLScriptElement[]) => {
    scripts.forEach(script => {
        if (scriptRef.current) {
            scriptRef.current.removeChild(script)
        }
    })
}