import React, { FC, ReactNode } from 'react'
import Spacing from '../../components/partials/Spacing'

interface IHeroSection {
    image: ReactNode
    breadcrumbs: ReactNode
    title: ReactNode | string
    text: ReactNode | string
}

const HeroSection: FC<IHeroSection> = ({ image, breadcrumbs, title, text }) => {
    return (
        <>
            <section className="hero-margin">
                <div className="container-1">
                    <div className="d-flex flex-wrap mt-3">
                        <div className="col-md-6 pt-0">
                            {breadcrumbs}
                            <h1 className="mb-3">{title}</h1>
                            <div className="pr-5 mobile-padding-reset">{text}</div>
                        </div>
                        <div className="col-md-6 pt-0 ipad-margin-top">{image}</div>
                    </div>
                </div>
            </section>
            <Spacing />
        </>
    )
}

export default HeroSection
