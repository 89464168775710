import React, { FC, ReactNode } from 'react'
import Spacing from '../../components/partials/Spacing'

type TvideoOptions = {
    url: string
    title: string
}

interface IVideoSection {
    video: TvideoOptions
    title: string
    subtitle?: ReactNode | string | undefined
    text?: string | undefined
}

const VideoSection: FC<IVideoSection> = ({ video, title, subtitle, text }) => {
    return (
        <>
            <section className=''>
                <div className='container-1'>
                    <div className='d-flex flex-wrap justify-content-center'>
                        <div className='col-12 text-center mobile-margin-reset mobile-padding-reset'>
                            <p className='h2-like cera-pro pb-1 mobile-padding-reset'>{title}</p>
                            {subtitle && <h2 className='d-inline h3-like'>{subtitle}</h2>}
                            {text && <p className='d-inline'>{text}</p>}
                        </div>
                        <div className='col-9'>
                            <div className='cloud-aws-msk-video'>
                                <iframe
                                    title={video.title}
                                    src={video.url}
                                    style={{
                                        position: 'absolute',
                                        top: '0',
                                        left: '0',
                                        width: '100%',
                                        height: '100%',
                                    }}
                                    // data-src={video.url}
                                    frameBorder='0'
                                    allow='autoplay; fullscreen'
                                    allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Spacing />
        </>
    )
}

export default VideoSection
