import React, { FC, ReactNode } from 'react'
import Spacing from '../../components/partials/Spacing'

interface IDiagramSection {
    title: ReactNode
    image: ReactNode
}

const DiagramSection: FC<IDiagramSection> = ({ title, image }) => {
    return (
        <>
            <section>
                <div className='container-1 mobile-margin-top'>
                    <div className='d-flex flex-wrap justify-content-center'>
                        <div className='col-sm-5 col-10'>
                            <div className='text-center'>{title}</div>
                        </div>
                    </div>

                    {image}
                </div>
            </section>
            <Spacing />
        </>
    )
}

export default DiagramSection
