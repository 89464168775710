import React, { FC, ReactNode } from 'react'
import Spacing from '../../components/partials/Spacing'

type TBlog = {
    image: ReactNode
    title: ReactNode
    type: string
    callback?: () => void
    openInNewTab?: boolean
    url: string
}

interface IBlogSection {
    title?: string
    blogs: TBlog[]
}

const BlogSection: FC<IBlogSection> = ({ blogs, title }) => {
    const getButton = (blog: TBlog) => {
        if (['WEBINAR', 'VIDEO', 'EPISODE'].includes(blog.type)) {
            return (
                <button
                    className='webinar-btn primary-text home-link-box mb-2'
                    onClick={blog.callback}>
                    Watch now
                </button>
            )
        }

        if (blog.type === 'LIVE STREAM') {
            return (
                <a
                    className='primary-text home-link-box'
                    href={blog.url}
                    target={blog.openInNewTab ? '_blank' : ''}
                    rel='noreferrer'>
                    Add to calendar <i className='red-pointer-right'></i>
                </a>
            )
        }

        return (
            <a
                className='primary-text home-link-box'
                href={blog.url}
                target={blog.openInNewTab ? '_blank' : ''}
                rel='noreferrer'>
                Read blog <i className='red-pointer-right'></i>
            </a>
        )
    }

    return (
        <>
            <section>
                <div className='container-1'>
                    {title && (
                        <div className='text-center mb-3'>
                            <h2 className='h1-like'>{title}</h2>
                        </div>
                    )}
                    <div className='d-flex flex-wrap'>
                        {blogs.map((blog) => (
                            <div key={blog.url} className='col-md col-sm-6 col-12 mt-1'>
                                <div className='home-three-items-box border-top-0 rounded-1 '>
                                    <div className={'blog-image-wrap'}>{blog.image}</div>
                                    <div className='pt-2 pb-4 px-3'>
                                        <p className='h4-like mb-4'>{blog.type}</p>
                                        {blog.title}
                                        {getButton(blog)}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <Spacing />
        </>
    )
}

export default BlogSection
